<template>
  <div style="margin: 10px; width: calc(100vw - 85px)">
    <el-dialog top="5vh" width="80vw" :close-on-click-modal="false" :visible.sync="isOpenUserForm" :title="isNewUser ? $t('settings.user_add') : $t('settings.user_edit')">
      <el-form ref="user" v-loading="loading" :model="userForm" :rules="rules">
        <el-tabs stretch @tab-click="handleTabClick">
          <el-tab-pane>
            <span slot="label">
              <i class="fas fa-user"></i>
            </span>
            <div style="height: calc(45vh); overflow: auto;">
              <div class="form-item-block">
                <div class="form-item-row">
                  <el-form-item class="form-item-block-left">
                    <el-select v-model="userForm.userType" value="" :placeholder="$t('settings.user_form_type_placeholder')">
                      <el-option v-for="type in userTypes" :key="type.value" :value="type.value" :label="$t('profile.'+type.text)" />
                    </el-select>
                  </el-form-item>
                  <el-form-item class="form-item-block-right">
                    <el-checkbox v-if="userForm.userType === 'manager'" v-model="userForm.manageDevices">{{ $t('settings.user_form_manage_devices') }}</el-checkbox>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item-block">
                <div class="form-item-row">
                  <el-form-item class="form-item-block-left" :label="$t('settings.user_name')" prop="name">
                    <el-input v-model="userForm.name" />
                  </el-form-item>
                  <el-form-item class="form-item-block-right" :label="$t('settings.user_email')" prop="email">
                    <el-input v-model="userForm.email" />
                  </el-form-item>
                </div>
              </div>
              <div class="form-item-block">
                <div class="form-item-row">
                  <el-form-item class="form-item-block-left" :label="$t('settings.user_language')">
                    <el-select v-model="userForm.lang">
                      <el-option v-for="lang in languages" :key="lang.value" :label="lang.text" :value="lang.value" />
                    </el-select>
                  </el-form-item>
                  <el-form-item class="form-item-block-right" :label="$t('settings.user_timezone')">
                    <el-select v-model="userForm.timezone">
                      <el-option v-for="timezone in timezones" :key="timezone.value" :label="timezone.text" :value="timezone.value" />
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item-block">
                <div class="form-item-row">
                  <el-form-item class="form-item-block-left" :label="$t('settings.user_password')" prop="password">
                    <el-input
                      :key="passwordType"
                      ref="password"
                      v-model="userForm.password"
                      :show-password="true"
                      :type="passwordType"
                      name="password"
                      :disabled="!isNewUser && !updatePassword"
                      :style="'width: '+(isNewUser ? '300px' : '200px')"
                    />
                    <el-button
                      v-if="!isNewUser"
                      :loading="loading"
                      size="small"
                      style="float: right; height: 40px"
                      @click="handleUpdatePassword()"
                    ><i class="fas fa-sync-alt"></i></el-button>
                  </el-form-item>
                  <el-form-item class="form-item-block-right" :label="$t('settings.user_phone')">
                    <el-input v-model="userForm.phone" />
                  </el-form-item>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="userForm.userType !== 'operator'">
            <span slot="label">
              <i class="fas fa-users"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="userForm.userSelectedUsers"
                class="transfer-user"
                filterable
                :filter-placeholder="$t('report.selector_search')"
                :titles="[$t('settings.users'), $t('Selecionados')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="users"
                :render-content="renderVehicle"
              >
              </el-transfer>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <i class="fas fa-car"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="userForm.userSelectedDevices"
                class="transfer-user"
                filterable
                :filter-placeholder="$t('report.selector_search')"
                :titles="[$t('settings.vehicles'), $t('report.select_vehicles')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="devices"
                :render-content="renderVehicle"
              >
              </el-transfer>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane v-if="groups.length">
            <span slot="label">
              <i class="fas fa-grip-horizontal"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="userForm.userSelectedGroups"
                class="transfer-user"
                filterable
                :filter-placeholder="$t('report.selector_search')"
                :titles="[$t('settings.groups'), $t('report.select_groups')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="groups"
                :render-content="renderFunc"
              >
              </el-transfer>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane v-if="drivers.length">
            <span slot="label">
              <i class="fas fa-address-card"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="userForm.userSelectedDrivers"
                class="transfer-user"
                filterable
                :filter-placeholder="$t('report.selector_search')"
                :titles="[$t('settings.drivers'), $t('report.select_groups')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="drivers"
                :render-content="renderFunc"
              >
              </el-transfer>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane name="geofences">
            <span slot="label">
              <i class="fas fa-map-marked"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="userForm.userSelectedGeofences"
                class="transfer-user"
                filterable
                :filter-placeholder="$t('report.selector_search')"
                :titles="[$t('settings.zone'), $t('report.select_groups')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="renderedGeofences"
                :render-content="renderFunc"
              >
              </el-transfer>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane v-if="filteredAlerts.length">
            <span slot="label">
              <i class="fas fa-bell"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="userForm.userSelectedAlerts"
                class="transfer-user"
                filterable
                :filter-placeholder="$t('report.selector_search')"
                :titles="[$t('settings.alerts'), $t('report.select_groups')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="filteredAlerts"
                :render-content="renderFunc"
              >
              </el-transfer>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane v-if="permissions.length">
            <span slot="label">
              <i class="far fa-sticky-note"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="userForm.userSelectedReports"
                class="transfer-user"
                filterable
                :filter-placeholder="$t('report.selector_search')"
                :titles="[$t('settings.reports'), $t('report.select_groups')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="permissions"
                :render-content="renderFunc"
              >
              </el-transfer>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane v-if="modules.length">
            <span slot="label">
              <i class="fa fa-cube"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="userForm.userSelectedModules"
                class="transfer-user"
                filterable
                :filter-placeholder="$t('report.selector_search')"
                :titles="[$t('settings.modules'), $t('report.select_groups')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="modules"
                :render-content="renderFunc"
              >
              </el-transfer>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane v-if="commands.length">
            <span slot="label">
              <i class="fa fa-paper-plane"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="userForm.userSelectedCommands"
                class="transfer-user"
                filterable
                :filter-placeholder="$t('report.selector_search')"
                :titles="[$t('settings.commands'), $t('report.select_groups')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="userCommands"
                :render-content="renderFunc"
              >
              </el-transfer>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="info"
          size="small"
          @click="handleCancelForm"
        >{{ $t('settings.form_cancel') }}</el-button>
        <el-button
          :loading="loading"
          type="success"
          size="small"
          @click="handleSubmitForm"
        >{{ $t('settings.form_save') }}</el-button>
      </span>
    </el-dialog>

    <el-table
      height="calc(100vh - 100px)"
      :row-style="tableRowStyle"
      :header-cell-style="tableHeaderStyle"
      :data="filteredUsers"
      @row-dblclick="handleDoubleClick"
    >
      <el-table-column :label="$t('settings.user_type')">
        <template slot-scope="scope">
          <span>{{ userType(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('settings.user_name')"
        prop="name"
        sortable
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        :label="$t('settings.user_email')"
        prop="email"
        sortable
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        :label="$t('settings.user_phone')"
        prop="phone"
        sortable
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column label="" min-width="90px">
        <template slot="header">
          <div style="float: left">
          </div>
          <div style="float: right">
            <el-tooltip :content="$t('settings.add')" placement="top">
              <el-button
                class="formButton"
                size="small"
                @click="handleAddUser"
              ><i class="fas fa-plus"></i></el-button>
            </el-tooltip>
            <el-button size="mini" :loading="downloadLoading" icon="el-icon-document" type="primary" class="tableButton" @click="handleDownload">Excel</el-button>
          </div>
        </template>
        <template slot-scope="scope">
          <el-tooltip :content="$t('settings.delete')" placement="top">
            <el-button
              class="formButton"
              size="small"
              type="danger"
              @click="handleDelete(scope.row)"
            ><i class="fas fa-trash-alt"></i></el-button>
          </el-tooltip>
          <el-tooltip :content="$t('settings.edit')" placement="top">
            <el-button
              size="small"
              class="formButton"
              @click="handleEdit(scope.row)"
            ><i class="fas fa-edit"></i></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total"
      :total="filteredUsers.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { vm } from '@/main'
import { traccar } from '@/api/traccar-api'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { languages } from '@/lang'
import { timezones } from '@/utils/consts'
import { fleetmapModules, moduleIsActive } from '@/utils/modules'
import store from '@/store'
import { pinmeapi } from '@/api/pinme'
import { partnerData } from '@/utils/partner'
export default {
  name: 'Users',
  data() {
    return {
      downloadLoading: false,
      tabName: '',
      userGeofencesLoaded: false,
      renderedGeofences: [],
      isOpenUserForm: false,
      isNewUser: true,
      updatePassword: false,
      selectedUser: null,
      userForm: {
        name: '',
        email: '',
        phone: '',
        password: '',
        userType: '',
        manageDevices: false,
        lang: '',
        timezone: '',
        userDrivers: [],
        userGeofences: [],
        userGroups: [],
        userDevices: [],
        userCommands: [],
        userUsers: [],
        userSelectedDrivers: [],
        userSelectedGeofences: [],
        userSelectedAlerts: [],
        userSelectedGroups: [],
        userSelectedDevices: [],
        userSelectedUsers: [],
        userSelectedReports: [],
        userSelectedModules: [],
        userSelectedCommands: []
      },
      userTypes: [
        { value: 'manager', text: 'user_type_manager' },
        { value: 'operator', text: 'user_type_operator' }
      ],
      rules: {
        name: [
          { required: true, message: this.$t('settings.name_required'), trigger: 'blur' }
        ],
        email: [
          { type: partnerData().emailRuleType || 'email', message: this.$t('settings.email_format_invalid'), trigger: 'blur' },
          { required: true, message: this.$t('profile.email_required'), trigger: 'blur' }
        ],
        password: [
          { required: true, min: 7, message: this.$t('profile.user_password_lengh'), trigger: 'blur' }
        ]
      },
      passwordType: 'password',
      loading: false,
      languages: languages,
      timezones: timezones
    }
  },
  computed: {
    ...mapGetters(['user', 'users', 'drivers', 'groups', 'devices', 'geofences', 'alerts', 'search', 'commands']),
    filteredUsers() {
      return this.users.filter(data => !this.search ||
        (data.name && data.name.toLowerCase().includes(this.search.toLowerCase())) ||
        (data.email && data.email.toLowerCase().includes(this.search.toLowerCase())) ||
        (data.phone && data.phone.toLowerCase().includes(this.search.toLowerCase())) ||
        this.userType(data).toLowerCase().includes(this.search.toLowerCase()))
    },
    filteredAlerts() {
      return this.alerts.map(alert => {
        const notification = alert.notification
        if (notification.type === 'alarm') {
          return { 'id': notification.id, 'name': this.$t('settings.alert_' + notification.attributes.alarms) }
        }
        return { 'id': notification.id, 'name': this.$t('settings.alert_' + notification.type) }
      })
    },
    permissions() {
      return this.user.attributes.permissions ? this.user.attributes.permissions.map(p => {
        return { 'id': p, 'name': this.$t('permissions.' + p) }
      }) : []
    },
    modules() {
      const modules = []
      for (const module in fleetmapModules) {
        if (moduleIsActive(store.getters.user, module)) {
          modules.push({ 'id': module, 'name': this.$t('route.' + module) })
        }
      }
      return modules
    },
    userCommands() {
      const userCommands = []
      for (const command of this.commands) {
        userCommands.push({ 'id': command.id, 'name': command.description })
      }
      return userCommands
    }
  },
  methods: {
    tableRowStyle() {
      return 'font-size: 14px'
    },
    tableHeaderStyle() {
      return 'font-size: 14px'
    },
    userType(user) {
      if (user.readonly) {
        return this.$t('profile.user_type_operator')
      }

      return this.$t('profile.user_type_manager')
    },
    handleAddUser() {
      this.isNewUser = true
      this.selectedUser = null
      this.userForm.name = ''
      this.userForm.email = ''
      this.userForm.phone = ''
      this.userForm.password = ''
      this.userForm.manageDevices = false
      this.userForm.lang = this.user.attributes.lang
      this.userForm.timezone = this.user.attributes.timezone
      this.userForm.userDrivers = []
      this.userForm.userGeofences = []
      this.userForm.userGroups = []
      this.userForm.userAlerts = []
      this.userForm.userDevices = []
      this.userForm.userCommands = []
      this.userForm.userSelectedDrivers = []
      this.userForm.userSelectedGeofences = []
      this.userForm.userSelectedGroups = []
      this.userForm.userSelectedReports = []
      this.userForm.userSelectedDevices = []
      this.userForm.userSelectedAlerts = []
      this.userForm.userSelectedModules = [fleetmapModules.map, fleetmapModules.reports]
      this.userForm.userSelectedCommands = []
      this.isOpenUserForm = !this.isOpenUserForm
    },
    async handleEdit(row) {
      try {
        const self = this
        this.isOpenUserForm = !this.isOpenUserForm
        this.loading = true
        this.isNewUser = false
        this.updatePassword = false
        this.selectedUser = row
        this.userForm.userSelectedReports = row.attributes.permissions ? row.attributes.permissions : []
        this.userForm.name = row.name
        this.userForm.email = row.email
        this.userForm.phone = row.phone
        this.userForm.password = '********'
        this.userForm.lang = row.attributes.lang
        this.userForm.timezone = row.attributes.timezone ? row.attributes.timezone : this.user.attributes.timezone
        this.userForm.userType = row.readonly ? 'operator' : 'manager'
        this.userForm.manageDevices = !row.deviceReadonly
        await traccar.driversByUser(this.selectedUser.id).then(function(response) {
          self.userForm.userDrivers = self.userForm.userSelectedDrivers = response.data.map(d => d.id)
        })
        await traccar.groupsByUser(self.selectedUser.id).then(function(response) {
          self.userForm.userGroups = self.userForm.userSelectedGroups = response.data.map(g => g.id)
        })
        await traccar.devicesByUser(self.selectedUser.id).then(function(response) {
          self.userForm.userDevices = self.userForm.userSelectedDevices = response.data.map(d => d.id)
        })
        await traccar.alertsByUser(self.selectedUser.id).then(function(response) {
          self.userForm.userAlerts = self.userForm.userSelectedAlerts = response.data.map(a => a.id)
        })
        await traccar.commandsByUser(self.selectedUser.id).then(function(response) {
          self.userForm.userCommands = self.userForm.userSelectedCommands = response.data.map(a => a.id)
        })
        this.userForm.userSelectedUsers = this.userForm.userUsers = await pinmeapi.usersByUser(self.selectedUser.id)
          .then(u => u.map(u => u.id))

        for (const module in fleetmapModules) {
          if (moduleIsActive(row, module)) {
            this.userForm.userSelectedModules.push(module)
          }
        }
      } catch (e) {
        Vue.$log.error(e)
        await this.$alert(e)
      } finally {
        this.loading = false
      }
      await this.handleTabClick({ name: this.tabName })
    },

    async handleTabClick(tab) {
      this.tabName = tab.name
      // geofences
      if (tab.name === 'geofences') {
        this.loading = true
        if (this.selectedUser) {
          const geofences = await traccar.geofencesByUser(this.selectedUser.id).then(d => d.data)
          this.userForm.userSelectedGeofences = geofences.map(g => g.id)
        } else {
          this.userForm.userSelectedGeofences = []
        }
        this.userForm.userGeofences = this.userForm.userSelectedGeofences.slice()
        this.userGeofencesLoaded = true
        // wait for the loader to
        setTimeout(() => {
          this.renderedGeofences = this.geofences.map(g => ({ id: g.id, name: g.name }))
          this.loading = false
        }, 100)
      } else {
        this.renderedGeofences = []
      }
    },
    handleDoubleClick(row) {
      if (!row.userOfSubUser) {
        this.handleEdit(row)
      }
    },
    handleDelete(row) {
      this.$confirm(this.$t('settings.user_delete_info') + row.name, this.$t('settings.user_delete_title'), {
        confirmButtonText: this.$t('settings.form_confirm'),
        cancelButtonText: this.$t('settings.form_cancel')
      }).then(() => {
        traccar.deleteUser(row.id, this.userDeleted)
      }).catch((error) => {
        this.$log.error(error)
      })
    },
    handleCancelForm() {
      this.isOpenUserForm = false
    },
    async handleSubmitForm() {
      await new Promise((resolve, reject) => {
        this.$refs.user.validate((valid, fields) => {
          if (valid) { resolve() } else {
            this.$alert(
              Object.keys(fields).map(k => fields[k].map(e => e.message)).flat().join('<br>'),
              this.$t('Warning'),
              { dangerouslyUseHTMLString: true })
            reject()
          }
        })
      })
      this.loading = true
      if (this.isNewUser) {
        const newUser = {
          name: this.userForm.name,
          email: this.userForm.email,
          phone: this.userForm.phone,
          password: this.userForm.password,
          readonly: this.userForm.userType === 'operator',
          deviceReadonly: !this.userForm.manageDevices,
          attributes: {
            timezone: this.userForm.timezone,
            lang: this.userForm.lang,
            permissions: this.userForm.userSelectedReports,
            inactiveVehiclesEmail: this.userForm.userType === 'manager',
            scheduler: this.userForm.userSelectedModules.includes(fleetmapModules.scheduler),
            map: this.userForm.userSelectedModules.includes(fleetmapModules.map),
            dashboard: this.userForm.userSelectedModules.includes(fleetmapModules.dashboard),
            reports: this.userForm.userSelectedModules.includes(fleetmapModules.reports),
            settings: this.userForm.userSelectedModules.includes(fleetmapModules.settings),
            odoo: this.userForm.userSelectedModules.includes(fleetmapModules.odoo) && '/reports/odoo/'
          }
        }

        if (this.user.attributes.clientId) {
          newUser.attributes.clientId = this.user.attributes.clientId
        }

        if (this.user.attributes.companyId) {
          newUser.attributes.companyId = this.user.attributes.companyId
        }

        if (this.userForm.userSelectedModules.includes(fleetmapModules.legacy)) {
          newUser.attributes.legacy = this.user.attributes.legacy
        }

        traccar.addUser(newUser)
          .then(response => this.userCreated(response.data))
          .catch(reason => {
            this.loading = false
            if (reason.response.data.startsWith('Duplicate entry')) {
              this.$message({
                message: this.$t('settings.user_duplicated_entry'),
                type: 'warning',
                duration: 5 * 1000
              })
            } else if (reason.response.data.startsWith('Manager access required')) {
              this.$message({
                message: this.$t('settings.user_create_not_allowed'),
                type: 'warning',
                duration: 5 * 1000
              })
            } else {
              Vue.$log.error(reason)
            }
          })
      } else {
        const user = this.selectedUser
        user.name = this.userForm.name
        user.email = this.userForm.email
        user.phone = this.userForm.phone
        user.password = this.updatePassword ? this.userForm.password : ''
        user.readonly = this.userForm.userType === 'operator'
        user.deviceReadonly = !this.userForm.manageDevices
        user.attributes.permissions = this.userForm.userSelectedReports.filter(a => this.permissions.map(p => p.id).includes(a))
        user.attributes.lang = this.userForm.lang
        user.attributes.timezone = this.userForm.timezone
        user.attributes.scheduler = this.userForm.userSelectedModules.includes(fleetmapModules.scheduler)
        user.attributes.odoo = this.userForm.userSelectedModules.includes(fleetmapModules.odoo) && '/reports/odoo/'
        user.attributes.dashboard = this.userForm.userSelectedModules.includes(fleetmapModules.dashboard)
        user.attributes.reports = this.userForm.userSelectedModules.includes(fleetmapModules.reports)
        user.attributes.settings = this.userForm.userSelectedModules.includes(fleetmapModules.settings)
        user.attributes.map = this.userForm.userSelectedModules.includes(fleetmapModules.map)
        user.attributes.tacho = this.userForm.userSelectedModules.includes(fleetmapModules.tacho)
        // If operator set inactiveVehiclesEmail allways to false
        if (this.userForm.userType === 'operator') {
          user.attributes.inactiveVehiclesEmail = false
        }

        if (this.userForm.userSelectedModules.includes(fleetmapModules.legacy)) {
          user.attributes.legacy = this.user.attributes.legacy
        } else {
          delete user.attributes.legacy
        }

        pinmeapi.updateUser(user)
          .then(() => {
            this.userUpdated()
          })
          .catch(reason => {
            this.loading = false
            if (reason.response.data.startsWith('Duplicate entry')) {
              this.$message({
                message: this.$t('settings.user_duplicated_entry'),
                type: 'warning',
                duration: 5 * 1000
              })
            } else if (reason.response.data.startsWith('Manager access required')) {
              this.$message({
                message: this.$t('settings.user_edit_not_allowed'),
                type: 'warning',
                duration: 5 * 1000
              })
            } else {
              Vue.$log.error(reason)
            }
          })
      }
    },
    userCreated: async function(newUser) {
      try {
        await this.$store.dispatch('user/addUser', newUser)

        await this.updateUserPermissions(newUser)

        this.$message({
          type: 'success',
          message: this.$t('settings.user_created')
        })
      } catch (e) {
        Vue.$log.error(e)
      } finally {
        this.isOpenUserForm = false
        this.loading = false
      }
    },
    userUpdated: async function() {
      try {
        await this.updateUserPermissions(this.selectedUser)

        this.$message({
          type: 'success',
          message: this.$t('settings.user_updated')
        })
      } catch (e) {
        Vue.$log.error(e)
      } finally {
        this.isOpenUserForm = false
        this.loading = false
      }
    },
    getSelectedGeofencesFromGroups() {
      this.userForm.userSelectedGroups.forEach(groupId => {
        const group = this.groups.find(g => g.id === groupId)
        const geofenceIdsByGroup = group && group.geofences
          ? group.geofences.geofences.concat(group.geofences.linegeofences).concat(group.geofences.pois)
          : []
        geofenceIdsByGroup.forEach(geofenceId => {
          if (this.userForm.userSelectedGeofences.indexOf(geofenceId) === -1) {
            this.userForm.userSelectedGeofences.push(geofenceId)
          }
        })
      })
    },
    async updateUserPermissions(user) {
      const self = this
      this.getSelectedGeofencesFromGroups()
      const geofencesToRemove = this.userForm.userGeofences.filter(x => !this.userForm.userSelectedGeofences.includes(x))
      const geofencesToAdd = this.userForm.userSelectedGeofences.filter(x => !this.userForm.userGeofences.includes(x))

      const geofencePermissionsToRemove = geofencesToRemove.map(g => {
        return {
          userId: user.id,
          geofenceId: g
        }
      })
      const geofencePermissionsToAdd = geofencesToAdd.map(g => {
        return {
          userId: user.id,
          geofenceId: g
        }
      })

      await traccar.deleteAllPermissions(geofencePermissionsToRemove)
      await traccar.addAllPermissions(geofencePermissionsToAdd)

      const groupsToRemove = this.userForm.userGroups.filter(x => !self.userForm.userSelectedGroups.includes(x))
      const groupsToAdd = this.userForm.userSelectedGroups.filter(x => !self.userForm.userGroups.includes(x))

      const groupsPermissionsToRemove = groupsToRemove.map(g => {
        return {
          userId: user.id,
          groupId: g
        }
      })
      const groupPermissionsToAdd = groupsToAdd.map(g => {
        return {
          userId: user.id,
          groupId: g
        }
      })

      await traccar.deleteAllPermissions(groupsPermissionsToRemove)
      await traccar.addAllPermissions(groupPermissionsToAdd)

      const driversToRemove = this.userForm.userDrivers.filter(x => !self.userForm.userSelectedDrivers.includes(x))
      const driversToAdd = this.userForm.userSelectedDrivers.filter(x => !self.userForm.userDrivers.includes(x))

      const driversPermissionsToRemove = driversToRemove.map(d => {
        return {
          userId: user.id,
          driverId: d
        }
      })
      const driversPermissionsToAdd = driversToAdd.map(d => {
        return {
          userId: user.id,
          driverId: d
        }
      })

      await traccar.deleteAllPermissions(driversPermissionsToRemove)
      await traccar.addAllPermissions(driversPermissionsToAdd)

      const alertsToRemove = this.userForm.userAlerts.filter(x => !self.userForm.userSelectedAlerts.includes(x))
      const alertsToAdd = this.userForm.userSelectedAlerts.filter(x => !self.userForm.userAlerts.includes(x))

      const alertsPermissionsToRemove = alertsToRemove.map(d => {
        return {
          userId: user.id,
          notificationId: d
        }
      })
      const alertsPermissionsToAdd = alertsToAdd.map(d => {
        return {
          userId: user.id,
          notificationId: d
        }
      })

      await traccar.deleteAllPermissions(alertsPermissionsToRemove)
      await traccar.addAllPermissions(alertsPermissionsToAdd)

      const devicesToRemove = this.userForm.userDevices.filter(x => !self.userForm.userSelectedDevices.includes(x))
      const devicesToAdd = this.userForm.userSelectedDevices.filter(x => !self.userForm.userDevices.includes(x))

      const devicesPermissionsToRemove = devicesToRemove.map(d => {
        return {
          userId: user.id,
          deviceId: d
        }
      })
      const devicesPermissionsToAdd = devicesToAdd.map(d => {
        return {
          userId: user.id,
          deviceId: d
        }
      })

      await traccar.deleteAllPermissions(devicesPermissionsToRemove)
      await traccar.addAllPermissions(devicesPermissionsToAdd)

      const commandsToRemove = this.userForm.userCommands.filter(x => !self.userForm.userSelectedCommands.includes(x))
      const commandsToAdd = this.userForm.userSelectedCommands.filter(x => !self.userForm.userCommands.includes(x))

      const commandsPermissionsToRemove = commandsToRemove.map(d => {
        return {
          userId: user.id,
          commandId: d
        }
      })
      const commandsPermissionsToAdd = commandsToAdd.map(d => {
        return {
          userId: user.id,
          commandId: d
        }
      })

      await traccar.deleteAllPermissions(commandsPermissionsToRemove)
      await traccar.addAllPermissions(commandsPermissionsToAdd)

      const usersToRemove = this.userForm.userUsers.filter(x => !self.userForm.userSelectedUsers.includes(x))
      const usersToAdd = this.userForm.userSelectedUsers.filter(x => !self.userForm.userUsers.includes(x))

      const usersPermissionsToRemove = usersToRemove.map(d => {
        return {
          userId: user.id,
          managedUserId: d
        }
      })
      const usersPermissionsToAdd = usersToAdd.map(d => {
        return {
          userId: user.id,
          managedUserId: d
        }
      })

      await traccar.deleteAllPermissions(usersPermissionsToRemove)
      await traccar.addAllPermissions(usersPermissionsToAdd)

      if (!user.readonly && user.userLimit === 0) {
        user.userLimit = -1
        await pinmeapi.updateUser(user)
      }
    },
    userDeleted(id) {
      this.$log.debug('user deleted')
      this.$message({
        message: this.$t('settings.user_deleted'),
        type: 'success',
        duration: 5 * 1000
      })
      const user = vm.$store.state.user.users.find(u => u.id === id)
      this.$store.dispatch('user/removeUser', user)
    },
    renderFunc(h, option) {
      return <span title={option.name}>{option.name}</span>
    },
    renderVehicle(h, option) {
      const group = this.groups.find(g => g.id === option.groupId)
      return <span title={option.name}>{option.name} {group && ('(' + group.name + ')')}</span>
    },
    handleUpdatePassword() {
      this.updatePassword = true
      this.userForm.password = ''
    },
    handleDownload() {
      this.downloadLoading = true
      import('../../../utils/ExportExcel').then(excel => {
        const tHeader = [this.$t('settings.user_type'), this.$t('settings.user_name'), this.$t('settings.user_email')]
        const data = []
        console.log(this.users)
        this.filteredUsers.forEach(u => {
          data.push([this.userType(u), u.name, u.email])
        })
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: '',
          autoWidth: false,
          bookType: 'xlsx'
        })
        this.downloadLoading = false
      })
    },
    doNothing(scope) {
      /* this method is here because we need the attribute 'slot-scope = "scope"' on the template
       for search box to work, but to be able to commit the variable 'scope' it must be used*/
    }
  }
}
</script>
<style lang="scss">
.transfer-user {
  .el-transfer-panel {
    height: calc(50vh);
    .el-transfer-panel__list {
      height: calc(50vh - 120px);
    }
  }
}
</style>
<style lang="scss" scoped>

  .form-item-block-left{
    display: table-cell;
    width: 300px;
    padding-right: 50px;
  }
  .form-item-block-right{
    width: 300px;
    display: table-cell;
  }

  .el-form-item__label {
    line-height: 30px;
  }
  .el-form-item {
    margin-bottom: 10px;
  }

  .formButton {
    float: right;
    margin-right: 10px;
  }

  .el-dialog__body {
    padding-top: 0;
  }

  .el-select {
    width: 100%;
  }

</style>
